import Joi from 'joi'

import { CreateInvite } from '../../types/invite.type'
import { SingleLineForm } from '../single-line-form/SingleLineForm'

export function InviteForm({ onSubmit }: { onSubmit: any }) {
  return (
    <>
      <SingleLineForm<CreateInvite>
        defaultValues={{ type: 'company' }}
        onSubmit={async (data) => {
          // console.debug('calling onSubmit')
          await onSubmit(data)
          // console.debug('call to onSubmit is successful')
        }}
        focusOnLoad={'name'}
        fields={[
          {
            name: 'name',
            label: 'Name',
            formRegisterOptions: { required: true, maxLength: 70 },
            type: 'text',
          },
          {
            name: 'email',
            label: 'Email',
            formRegisterOptions: {
              required: true,
              validate: (value) => {
                const result = Joi.string().email({ tlds: false }).validate(value)
                return !result.error?.message
              },
            },
            type: 'text',
          },
          {
            name: 'phoneNumber',
            label: 'Phone Number',
            formRegisterOptions: { required: true, minLength: 7, maxLength: 15 },
            type: 'text',
          },
        ]}
        submitButtonText={'Send Invite'}
      />
    </>
  )
}
