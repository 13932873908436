import React from 'react'

import { Logo } from '../../logo/Logo'

import styles from './sidebar-header.module.scss'

export const SidebarHeader = () => {
  return (
    <span className={styles.selected}>
      <Logo className={styles.appLogo} />
      <span className={styles.selectedDetails}>
        <span className={styles.appName}>Counts</span>
        <span className={styles.companyName}>Counts Control Center</span>
      </span>
    </span>
  )
}
