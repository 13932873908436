import React from 'react'
import { Button as BsButton, ButtonProps as BsButtonProps } from 'react-bootstrap'

export interface ButtonProps extends BsButtonProps {
  variant?: 'primary' | 'normal' | 'transparent'
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  return <BsButton ref={ref} {...props} />
})
