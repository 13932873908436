import styles from './Loader.module.scss'

export function Loader() {
  return (
    <div className={styles.loaderWrap}>
      <div className={styles.loader}>
        <div />
        <div />
        <div />
      </div>
    </div>
  )
}
