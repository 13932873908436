import { Collapse, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material'
import { Table as ReactTable, Row, flexRender } from '@tanstack/react-table'
import classNames from 'classnames'
import { Fragment, ReactElement } from 'react'

import styles from './data-table.module.scss'

export interface DataTableProps<T> {
  table: ReactTable<T>
  hoverRows?: boolean
  renderSubComponent?: (props: { row: Row<T> }) => ReactElement
}

export function DataTable<T>({ table, hoverRows, renderSubComponent }: DataTableProps<T>) {
  return (
    <TableContainer className={styles.container}>
      <Table stickyHeader={true} className={styles.table}>
        <TableHead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableCell key={header.id} className={classNames(styles.cell, (header.column.columnDef.meta as any)?.className)}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <Fragment key={row.id}>
              <TableRow hover={hoverRows}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id} className={classNames(styles.cell, (cell.column.columnDef.meta as any)?.className)}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
              {renderSubComponent && (
                <TableRow>
                  <TableCell colSpan={row.getVisibleCells().length} className={classNames(styles.subComponentCell)}>
                    <Collapse in={row.getIsExpanded()} mountOnEnter={true} unmountOnExit={true}>
                      <div className={styles.subComponent}>{renderSubComponent({ row: row })}</div>
                    </Collapse>
                  </TableCell>
                </TableRow>
              )}
            </Fragment>
          ))}
        </TableBody>
        <TableFooter>
          {table.getFooterGroups().map((footerGroup) => (
            <TableRow key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <TableCell key={header.id} className={classNames(styles.cell, (header.column.columnDef.meta as any)?.className)}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableFooter>
      </Table>
    </TableContainer>
  )
}
