import jwtDecode from 'jwt-decode'
import { Navigate, Outlet, matchPath, useLocation } from 'react-router-dom'

import { NavRoutes } from '../app/AppRouter'
import { useAuthClient } from '../auth/useAuthClient'
import { Sidebar } from '../components/sidebar/Sidebar'
import { SidebarHeader } from '../components/sidebar/header/SidebarHeader'
import { UserProfile } from '../components/sidebar/user-profile/UserProfile'
import { Topbar } from '../components/topbar/Topbar'

import styles from './AppLayout.module.scss'

export const AppLayout = () => {
  const { isAuthenticated, idToken } = useAuthClient()
  const location = useLocation()

  if (!isAuthenticated || !idToken) {
    return <Navigate to={'/login'} replace={true} />
  }

  const payload = jwtDecode<any>(idToken)
  const routes = NavRoutes
  const breadcrumb =
    routes
      .flatMap(
        (r) => r.children?.map((c) => ({ ...c, path: r.path + '/' + c.path, breadcrumb: [r.title, c.title] })) || { ...r, breadcrumb: [r.title] },
      )
      .filter((r) => r.path !== undefined && matchPath(r.path, location.pathname))[0]?.breadcrumb ?? []

  return (
    <div className={styles.app}>
      <Sidebar header={<SidebarHeader />} routes={routes} footer={<UserProfile name={payload.name} image={payload.picture} />} />
      <div className={styles.content}>
        <Topbar breadcrumb={breadcrumb} />
        <div className={styles.mainContent}>
          <Outlet />
        </div>
      </div>
    </div>
  )
}
