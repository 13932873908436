import { Navigate, Outlet, matchPath, useLocation } from 'react-router-dom'

import { InvitesProvider } from '../../providers/InvitesProvider'

export function InvitesPage() {
  const location = useLocation()
  const match = matchPath(location.pathname, '/invites')

  if (!!match) {
    return <Navigate to={'company'} replace={true} />
  }

  return (
    <InvitesProvider>
      <Outlet />
    </InvitesProvider>
  )
}
