import { Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, TextFieldProps } from '@mui/material'
import { Fragment, ReactElement, useEffect } from 'react'
import { Controller, DeepPartial, FieldPath, RegisterOptions, SubmitHandler, UseFormRegisterReturn, useForm } from 'react-hook-form'

import styles from './single-line-form.module.scss'

interface FormFieldProps<TFieldValues, TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> {
  name: TFieldName
  label: string
  type: 'text' | 'select'
  formRegisterOptions?: RegisterOptions<TFieldValues, TFieldName>
  componentProps?: Exclude<Omit<TextFieldProps, 'label' | 'className' | 'error' | 'helperText'>, UseFormRegisterReturn<TFieldName>>
  selectMenuItems?: { label: string | ReactElement; value?: string }[]
}

export interface SingleLineFormProps<T> {
  fields: FormFieldProps<T>[]
  submitButtonText?: string
  defaultValues?: DeepPartial<T>
  onSubmit?: SubmitHandler<T>
  focusOnLoad?: SingleLineFormProps<T>['fields'][number]['name']
}

export function SingleLineForm<T>({ defaultValues, onSubmit, fields, focusOnLoad, submitButtonText = 'Submit' }: SingleLineFormProps<T>) {
  const form = useForm<T>({ defaultValues: defaultValues })

  useEffect(() => {
    if (focusOnLoad) {
      form.setFocus(focusOnLoad, { shouldSelect: true })
      form.reset()
    }
  }, [focusOnLoad, form, form.formState.isSubmitSuccessful])

  const _onSubmit: SubmitHandler<T> = async (data) => {
    if (onSubmit) {
      await onSubmit(data)
      form.reset()
    }
  }

  return (
    <form className={styles.form} onSubmit={form.handleSubmit(_onSubmit)}>
      {fields.map((formField, i) => (
        <Fragment key={i}>
          {formField.type === 'text' ? (
            <TextField
              {...form.register(formField.name, formField.formRegisterOptions)}
              label={formField.label}
              className={styles.input}
              autoComplete={formField.componentProps?.autoComplete ?? 'off'}
              error={!!(form.formState.errors as any)[formField.name]}
              helperText={(form.formState.errors as any)[formField.name]?.message}
              disabled={form.formState.isSubmitting || formField.componentProps?.disabled}
            />
          ) : formField.type === 'select' ? (
            <Controller
              name={formField.name}
              control={form.control}
              rules={formField.formRegisterOptions}
              render={({ field }) => (
                <FormControl className={styles.input}>
                  <InputLabel>{formField.label}</InputLabel>
                  <Select
                    label={formField.label}
                    value={field.value ?? ''}
                    onChange={field.onChange}
                    name={field.name}
                    ref={field.ref}
                    onBlur={field.onBlur}
                    error={!!(form.formState.errors as any)[formField.name]}
                    disabled={form.formState.isSubmitting || formField.componentProps?.disabled}
                  >
                    {(formField.selectMenuItems ?? []).map((menuItem, i) => (
                      <MenuItem key={i} value={menuItem.value}>
                        {menuItem.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{(form.formState.errors as any)[formField.name]?.message}</FormHelperText>
                </FormControl>
              )}
            />
          ) : (
            <></>
          )}
        </Fragment>
      ))}

      <Button
        type={'submit'}
        variant={'contained'}
        className={styles.submit}
        disableElevation={true}
        style={{ backgroundColor: '#1a1a1a' }}
        disabled={form.formState.isSubmitting}
      >
        {submitButtonText}
      </Button>
    </form>
  )
}
