import { QueryObserverResult, useQueries } from '@tanstack/react-query'
import { ProviderProps, createContext, useContext } from 'react'

import { useApiClient } from '../app/useApiClient'
import { Loader } from '../components/loader/Loader'
import type { Company } from '../types/company.type'
import type { User } from '../types/user.type'

interface Db {
  companies: Company[]
  refreshCompanies: QueryObserverResult<Company[]>['refetch']

  users: User[]
  refreshUsers: QueryObserverResult<User[]>['refetch']
}

const DbContext = createContext<Db>(undefined as any as Db)

export function DbProvider({ children }: Pick<ProviderProps<any>, 'children'>) {
  const apiClient = useApiClient()

  const dbQueries = useQueries({
    queries: [
      {
        queryKey: ['companies'],
        queryFn: async () => {
          const response = await apiClient.get<Company[]>('companies')
          return response.data
        },
      },
      {
        queryKey: ['users'],
        queryFn: async () => {
          const response = await apiClient.get<User[]>('users')
          return response.data
        },
      },
    ],
  })

  if (dbQueries.some((x) => x.isLoading)) {
    return <Loader />
  } else {
    const dbValue: Db = {
      companies: dbQueries[0].data ?? [],
      refreshCompanies: dbQueries[0].refetch,
      users: dbQueries[1].data ?? [],
      refreshUsers: dbQueries[1].refetch,
    }
    return <DbContext.Provider value={dbValue}>{children}</DbContext.Provider>
  }
}

export function useDbData(): Db {
  const dbData = useContext(DbContext)

  if (!dbData) {
    throw new Error('DbProvider is not used')
  }

  return dbData
}
