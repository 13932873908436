import React from 'react'

import { ErrorComponent } from '../components/error/ErrorComponent'

export class AppErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ error, errorInfo })
  }

  render() {
    if (this.state.errorInfo) {
      const title = 'Aw, snap!'
      const summary = 'Unable to process request'
      const { componentStack: stack } = this.state.errorInfo || {}

      return (
        <React.Fragment>
          <ErrorComponent title={title} summary={summary} error={this.state.error} stack={stack} />
        </React.Fragment>
      )
    } else {
      return this.props.children
    }
  }
}
