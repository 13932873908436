import { ReactComponent } from '*.svg'
import { Storage } from '@mui/icons-material'
import { ReactElement } from 'react'
import { Navigate, RouteObject, useRoutes } from 'react-router-dom'

import { ReactComponent as InvitesIcon } from '../assets/icons/invites.svg'
import { AppLayout } from '../layouts/AppLayout'
import { PublicLayout } from '../layouts/PublicLayout'
import { CompanyListPage } from '../pages/companies/CompanyListPage'
import { CompanyInvitesPage } from '../pages/company-invites/CompanyInvitesPage'
import { DatabaseLayoutPage } from '../pages/database/DatabaseLayoutPage'
import { InvitesPage } from '../pages/invites/InvitesPage'
import { LoginPage } from '../pages/login/LoginPage'

export interface NavRoute extends RouteObject {
  title: string
  icon?: ReactElement<typeof ReactComponent>
  children?: NavRoute[]
}

export const NavRoutes: NavRoute[] = [
  {
    path: 'invites',
    title: 'Invites',
    icon: <InvitesIcon />,
    element: <InvitesPage />,
    children: [
      {
        path: 'company',
        title: 'Company Owner',
        element: <CompanyInvitesPage />,
      },
    ],
  },
  {
    path: 'db',
    title: 'Database',
    icon: <Storage />,
    element: <DatabaseLayoutPage />,
    children: [
      {
        path: 'companies',
        title: 'Companies',
        element: <CompanyListPage />,
      },
    ],
  },
]

export const AppRouter = () => {
  return useRoutes([
    {
      path: '/login',
      element: <PublicLayout />,
      children: [{ path: '', element: <LoginPage /> }],
    },
    {
      path: '/',
      children: [
        { index: true, element: <Navigate to={'invites'} /> },
        { path: '', element: <AppLayout />, children: NavRoutes },
      ],
    },
  ])
}
