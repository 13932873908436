import { ReactComponent as LogoIcon } from '../../assets/icons/logo.svg'

import styles from './logo.module.scss'

interface LogoProps {
  className?: string
  variant?: 'color' | 'white'
}

export const Logo = ({ className, variant }: LogoProps) => {
  variant = variant || 'color'

  return (
    <div className={className}>
      <LogoIcon />
      {/*<span className={`${styles.icon} ${styles[variant]}`}>C</span>*/}
    </div>
  )
}
