import React from 'react'

import { Config } from './Config'

const defaultConfig: Config = {
  appName: 'counts-control-center',
  apiUrl: '',
}

export const ConfigContext = React.createContext<Config>(defaultConfig)
