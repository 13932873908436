import { ReactComponent as SignInMicrosoftButton } from '../../assets/icons/signin-microsoft.svg'
import { useConfig } from '../../config/useConfig'
import { Button } from '../bootstrap/Button'

export const SignInMicrosoft = () => {
  const { appName, apiUrl } = useConfig()

  const loginUrl = new URL(`auth/${appName}/microsoft/login`, apiUrl + '/')
  loginUrl.searchParams.set('post_login_redirect_uri', window.location.href)
  loginUrl.searchParams.set('redirect_uri', apiUrl)

  return (
    <>
      <a href={loginUrl.toString()}>
        <Button variant={'transparent'}>
          <SignInMicrosoftButton />
        </Button>
      </a>
    </>
  )
}
