import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import React, { useContext } from 'react'
import { useSearchParams } from 'react-router-dom'

import { AuthContext } from '../../auth/AuthContext'
import { Loader } from '../../components/loader/Loader'
import { Logo } from '../../components/logo/Logo'
import { SignInMicrosoft } from '../../components/signin-microsoft/SignInMicrosoft'

import styles from './login-page.module.scss'

// const useLoginSearchParams = () => {
//   const [searchParams] = useSearchParams()
//
//   const statusParam = searchParams.get('status')
//   const typeParam = searchParams.get('type')
//   const codeChallengeParam = searchParams.get('code')
//   const errorParam = searchParams.get('error')
//
//   let status: boolean | undefined = undefined
//   let codeChallenge: string | undefined = undefined
//   let redirectError: string | undefined = undefined
//
//   if (statusParam === '1') {
//     status = true
//     if (typeParam === 'google-sign-in') {
//       if (codeChallengeParam) {
//         codeChallenge = encodeURIComponent(codeChallengeParam)
//       }
//     }
//   } else if (statusParam === '0') {
//     status = false
//     if (errorParam) {
//       redirectError = errorParam
//     }
//   }
//
//   return { status, codeChallenge, redirectError }
// }

export const LoginPage = () => {
  // const { handleGoogleSignInRedirect } = useContext(AuthContext)
  // const { status, codeChallenge, redirectError } = useLoginSearchParams()
  //
  // const { isLoading, error: googleSignInError } = useQuery<void, AxiosError<any>>(
  //   'login',
  //   () => {
  //     // handleGoogleSignInRedirect({ codeChallenge: codeChallenge as string })
  //   },
  //   { enabled: !!codeChallenge },
  // )
  //
  // const canShowLoginButton: boolean = !!(status === undefined || redirectError || googleSignInError)
  //
  // const errorMessage = googleSignInError?.response?.data?.message || googleSignInError?.message || redirectError

  return (
    <React.Fragment>
      <div className={styles.page}>
        {/*<span className={styles.content}>*/}
        <span className={styles.logo}>
          <Logo variant={'white'} />
          <span className={`${styles.logoText}`}>Counts</span>
        </span>
        <span className={styles.formPanel}>
          <div className={styles.headingContainer}>
            <h1>Welcome</h1>
            <h2>Get access to Counts Control Center</h2>
          </div>
          <span className={styles.form}>
            {/*{isLoading ? (*/}
            {/*  <Loader />*/}
            {/*) : (*/}
            {/*{errorMessage && <span className={styles.error}>{errorMessage}</span>}*/}
            <SignInMicrosoft />
            {/*<GoogleSignInButton canInitiate={canShowLoginButton} className={styles.loginButton} />*/}
            {/*<MicrosoftLoginButton />*/}
            {/*)}*/}
          </span>
        </span>
        {/*</span>*/}
      </div>
    </React.Fragment>
  )
}
