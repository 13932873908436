import axios from 'axios'

import { AuthClientOpts } from './AuthClient.interface'

const STORAGE_KEY_CODE_VERIFIER = 'code_verifier'
const STORAGE_KEY_CODE_CHALLENGE = 'code_challenge'

interface Token {
  access_token: string
  id_token: string
}

export class AuthClient {
  private readonly appName: string
  private readonly baseUrl: string
  private token?: Token

  constructor({ appName, baseUrl }: AuthClientOpts) {
    this.appName = appName
    this.baseUrl = baseUrl
  }

  get accessToken() {
    return this.token?.access_token
  }

  get isAuthenticated() {
    console.debug('isAuthenticated', this.token)
    return this.token !== undefined
  }

  get idToken() {
    return this.token?.id_token
  }

  // public readonly id: string
  // private readonly baseUrl: string
  // private readonly googleClientId: string
  //
  // private accessToken?: string
  // private idToken?: string
  //
  // constructor({ baseUrl, googleClientId }: AuthClientOpts) {
  //   this.id = nanoid()
  //   this.baseUrl = baseUrl
  //   this.googleClientId = googleClientId
  // }
  //
  // getAccessToken() {
  //   return this.accessToken
  // }
  //
  // getIdToken() {
  //   return this.idToken
  // }

  async refreshToken() {
    this.token = undefined
    const { data } = await axios.post(`${this.baseUrl}/auth/${this.appName}/sessions/access-token`, null, { withCredentials: true })
    this.token = data
  }

  //
  // async initiateGoogleSignIn(): Promise<GoogleSignInConfig> {
  //   const codeVerifier = createRandomString()
  //   const codeChallengeBuffer = await sha256(codeVerifier)
  //   const codeChallenge = bufferToBase64UrlEncoded(codeChallengeBuffer)
  //
  //   sessionStorage.setItem(STORAGE_KEY_CODE_VERIFIER, codeVerifier)
  //   sessionStorage.setItem(STORAGE_KEY_CODE_CHALLENGE, codeChallenge)
  //
  //   return { codeVerifier, codeChallenge, clientId: this.googleClientId, redirectUri: `${this.baseUrl}/auth/google-sign-in/redirect` }
  // }
  //
  // async handleGoogleSignInRedirect({ codeChallenge }: GoogleSignInRedirectOpts) {
  //   const codeVerifier = sessionStorage.getItem(STORAGE_KEY_CODE_VERIFIER)
  //   const cachedCodeChallenge = sessionStorage.getItem(STORAGE_KEY_CODE_CHALLENGE)
  //
  //   if (cachedCodeChallenge !== codeChallenge || !codeVerifier) {
  //     throw new Error('Login session has expired. Try again.')
  //   }
  //
  //   const { data } = await axios.post(
  //     `${this.baseUrl}/auth/google-sign-in/token`,
  //     {
  //       code_verifier: codeVerifier,
  //       code_challenge: codeChallenge,
  //     },
  //     { withCredentials: true },
  //   )
  //
  //   this.accessToken = data.access_token
  //   this.idToken = data.id_token
  //
  //   sessionStorage.removeItem(STORAGE_KEY_CODE_VERIFIER)
  //   sessionStorage.removeItem(STORAGE_KEY_CODE_CHALLENGE)
  // }

  async logout() {
    await axios.post(`${this.baseUrl}/auth/${this.appName}/sessions/revoke`, null, { withCredentials: true })
    this.token = undefined
  }
}
