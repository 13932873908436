import { Fragment } from 'react'

import { ReactComponent as ChevronIcon } from '../../assets/icons/chevron.svg'

import styles from './topbar.module.scss'

export interface TopbarProps {
  breadcrumb: string[]
}

export const Topbar = ({ breadcrumb }: TopbarProps) => {
  return (
    <header className={styles.topbar}>
      <span className={styles.pageInfo}>
        <span>
          <span className={styles.breadcrumb}>
            {breadcrumb.slice(0, -1).map((v, i) => (
              <Fragment key={i}>
                <span>{v}</span>
                <ChevronIcon className={styles.separator} />
              </Fragment>
            ))}
          </span>
          <h1 className={styles.title}>{breadcrumb[breadcrumb.length - 1]}</h1>
        </span>
      </span>

      <span className={styles.buttons} />
    </header>
  )
}
