import axios from 'axios'
import React, { ReactNode } from 'react'

import { AuthProvider } from '../auth/AuthProvider'
import { ConfigProvider } from '../config/ConfigProvider'

interface AppContextProviderProps {
  children: ReactNode | undefined
}

export const AppContextProvider = ({ children }: AppContextProviderProps) => {
  const providers = [ConfigProvider, AuthProvider]

  return providers.reverse().reduce((combinedProvider, CurrentProvider) => {
    return <CurrentProvider>{combinedProvider}</CurrentProvider>
  }, <React.Fragment>{children}</React.Fragment>)
}
