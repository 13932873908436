import React, { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { AuthContext } from '../auth/AuthContext'

export const PublicLayout = () => {
  const { isAuthenticated } = useContext(AuthContext)

  if (isAuthenticated) {
    return <Navigate to={'/'} replace={true} />
  }

  return (
    <React.Fragment>
      <Outlet />
    </React.Fragment>
  )
}
