import { MoreVert } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import classNames from 'classnames'
import dayjs from 'dayjs'

import { Invite, InviteStatusTitleMap } from '../../types/invite.type'
import { DataTable } from '../data-table/DataTable'

import styles from './invites-table.module.scss'

export const InvitesTable = ({ invites }: { invites: Invite[] }) => {
  const columnHelper = createColumnHelper<Invite>()

  const table = useReactTable({
    data: invites,
    debugTable: true,
    columns: [
      columnHelper.display({
        header: 'Send to',
        cell: (props) => <SendToCell invite={props.row.original} />,
        meta: {
          className: styles.colSendTo,
        },
      }),
      columnHelper.display({
        header: 'Sent by',
        cell: (props) => <SentByCell invite={props.row.original} />,
        meta: {
          className: styles.colSentBy,
        },
      }),
      columnHelper.display({
        header: 'Status',
        cell: (props) => <StatusCell invite={props.row.original} />,
        meta: {
          className: styles.colStatus,
        },
      }),
      columnHelper.display({
        header: 'Action',
        cell: (props) => <ActionCell invite={props.row.original} />,
        meta: {
          className: styles.colAction,
        },
      }),
    ],
    getCoreRowModel: getCoreRowModel(),
  })

  return <DataTable table={table} />
}

export function SendToCell({ invite }: { invite: Invite }) {
  return (
    <div className={styles.sendTo}>
      <div className={styles.name}>{invite.name}</div>
      <div className={styles.email}>{invite.email}</div>
      <div className={styles.email}>{invite.phoneNumber}</div>
    </div>
  )
}

export function SentByCell({ invite }: { invite: Invite }) {
  return (
    <div className={styles.sentBy}>
      <div>
        <span className={styles.name}>{invite.sentBy?.name}</span>
        <span className={styles.date}> | on {dayjs(invite.sentAt).format('D MMM YY')}</span>
      </div>
      <div className={styles.email}>{invite.sentBy?.email}</div>
    </div>
  )
}

export function StatusCell({ invite }: { invite: Invite }) {
  const prefixText = invite.status === 'new' ? 'Expires' : ''
  const relativeDate = invite.status === 'accepted' ? invite.updatedAt : invite.expiryAt

  return (
    <div className={styles.status}>
      <div className={classNames(styles.tag, styles[invite.status])}>{InviteStatusTitleMap[invite.status]}</div>
      <div className={styles.date}>
        {prefixText && <span>{prefixText} </span>}
        {relativeDate && <span>{dayjs().to(dayjs(relativeDate))}</span>}
      </div>
    </div>
  )
}

export function ActionCell({ invite }: { invite: Invite }) {
  return (
    <>
      <IconButton>
        <MoreVert />
      </IconButton>
    </>
  )
}
