import React from 'react'

import styles from './Error.module.scss'

export interface ErrorComponentProps {
  title: string
  summary: string
  error?: any
  stack?: string
}

export function ErrorComponent({ title, summary, error, stack }: ErrorComponentProps) {
  return (
    <React.Fragment>
      <div className={styles.wrap}>
        <h1>{title}</h1>
        <h2>{summary}</h2>
        {process.env.NODE_ENV === 'development' && (
          <div className={styles.error}>
            {error && (
              <React.Fragment>
                {error.name && <p>Error: {error.name}</p>}
                {error.message && <p>Message: {error.message}</p>}
                <p>Error Details:</p>
                <p>
                  {JSON.stringify(error, null, 2)
                    .split('\n')
                    .map((line) => (
                      <pre>{line}</pre>
                    ))}
                </p>
                <p>Stack:</p>
                <p>{stack && stack.split('\n').map((line) => <pre>{line}</pre>)}</p>
              </React.Fragment>
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  )
}
