import React, { Fragment, HTMLProps, ReactNode, useState } from 'react'
import { Collapse, NavLink } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { matchPath, useLocation } from 'react-router-dom'

import { NavRoute } from '../../app/AppRouter'
import { ReactComponent as CaretDownIcon } from '../../assets/icons/chevron.svg'

import styles from './sidebar.module.scss'

export interface SidebarProps {
  header: ReactNode
  routes: NavRoute[]
  footer: ReactNode
}

interface ItemProps extends Pick<HTMLProps<HTMLSpanElement>, 'onClick'> {
  route: NavRoute
  isActive?: boolean
  type?: 'item' | 'subItem'
  expanded?: boolean
}

interface SidebarMenuItemProps {
  route: NavRoute
}

const Item = ({ route, isActive = false, type = 'item', expanded, ...props }: ItemProps) => {
  return (
    <span className={[styles.navItem, styles[type], isActive && styles.active].join(' ')} {...props}>
      <span className={styles.icon}>{route.icon}</span>
      <span className={styles.content}>{route.title}</span>
      {expanded !== undefined && <CaretDownIcon className={`${styles.caret} ${!expanded ? styles.collapsed : ''}`} />}
    </span>
  )
}

interface LinkProps {
  path: string
  children: ReactNode
}

const Link = ({ path, children }: LinkProps) => {
  return (
    <LinkContainer to={path!}>
      <NavLink className={styles.link}>{children}</NavLink>
    </LinkContainer>
  )
}

const MenuItem = ({ route }: SidebarMenuItemProps) => {
  const childRoutes =
    route.children?.map((childRoute) => ({
      ...childRoute,
      ...(childRoute.path !== undefined && { path: [route.path, childRoute.path].join('/') }),
    })) || []

  const location = useLocation()
  const activeRoute = childRoutes.filter((childRoute) => matchPath(childRoute.path!, location.pathname))[0]

  const [open, setOpen] = useState(!!activeRoute)

  return (
    <div>
      <Item route={route} isActive={!!activeRoute} expanded={!!activeRoute || open} onClick={() => setOpen((open) => !open)} />
      <Collapse in={!!activeRoute || open}>
        <div className={styles.subMenu}>
          {childRoutes.map((childRoute, index) => (
            <Link key={index} path={childRoute.path!}>
              <Item route={childRoute} type={'subItem'} isActive={activeRoute?.path === childRoute.path} />
            </Link>
          ))}
        </div>
      </Collapse>
    </div>
  )
}

export const Sidebar = ({ header, routes, footer }: SidebarProps) => {
  const location = useLocation()
  return (
    <aside className={styles.sidebar}>
      <div>{header}</div>
      <nav className={styles.nav}>
        {routes.map((route, index) => (
          <Fragment key={index}>
            {route.children ? (
              <MenuItem route={route} />
            ) : (
              <Link path={route.path!}>
                <Item route={route} isActive={!!matchPath(route.path!, location.pathname)} />
              </Link>
            )}
          </Fragment>
        ))}
      </nav>
      <div>{footer}</div>
    </aside>
  )
}
