import { ThemeProvider, createTheme } from '@mui/material'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import React from 'react'
import { ToastContainer, toast } from 'react-toastify'

import { AppContextProvider } from './app/AppContextProvider'
import { AppErrorBoundary } from './app/AppErrorBoundary'
import { AppRouter } from './app/AppRouter'

import './App.scss'
import 'react-toastify/dist/ReactToastify.css'

export const App = () => {
  const style = getComputedStyle(document.documentElement)

  const theme = createTheme({
    palette: {
      primary: {
        main: style.getPropertyValue('--app-color-primary').trim(),
      },
    },
    typography: {
      fontFamily: style.getPropertyValue('--app-font-family').trim(),
    },
  })

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
      },
    },
  })

  return (
    <React.Fragment>
      <AppErrorBoundary>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <AppContextProvider>
              <AppRouter />
              <ToastContainer />
            </AppContextProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </AppErrorBoundary>
    </React.Fragment>
  )
}
