import { Chip, Tab, Tabs } from '@mui/material'
import { AxiosError, AxiosResponse } from 'axios'
import { useState } from 'react'
import { toast } from 'react-toastify'

import { useApiClient } from '../../app/useApiClient'
import { InviteForm } from '../../components/invites/InviteForm'
import { InvitesTable } from '../../components/invites/InvitesTable'
import { useCompanyInvites, useInvitesRefetch } from '../../providers/InvitesProvider'
import { CreateInvite, Invite, InviteStatus, InviteStatusTitleMap, InviteStatuses } from '../../types/invite.type'

import styles from './company-invites-page.module.scss'

export const CompanyInvitesPage = () => {
  const apiClient = useApiClient()

  const inviteMap = useCompanyInvites()
  const invitesRefetch = useInvitesRefetch()

  const onSubmit = async (createInvite: CreateInvite) => {
    try {
      // console.debug('calling API to create invite')
      const { data } = await apiClient.post<Invite, AxiosResponse<Invite>, CreateInvite>('invites', createInvite)

      toast(`Invite sent successfully to ${data.email}`, { type: 'success' })
      // console.debug('calling API to create invite success', data)

      await invitesRefetch()

      return data
    } catch (e) {
      const message = e instanceof AxiosError ? (e.response?.status === 409 ? ' - Already exists' : '') : ''

      toast('Unable to send invite' + message, { type: 'error' })
      throw e
    }
  }

  const [selectedTab, setSelectedTab] = useState<InviteStatus>('new')

  return (
    <div className={styles.page}>
      <InviteForm onSubmit={onSubmit} />

      <Tabs className={styles.tabs} value={selectedTab} onChange={(e, newSelectedTab) => setSelectedTab(newSelectedTab)}>
        {InviteStatuses.map((status) => (
          <Tab
            key={status}
            id={status}
            label={
              <span className={styles.tab}>
                <span>{InviteStatusTitleMap[status]}</span>
                <Chip
                  className={styles.chip}
                  label={((inviteMap ?? {})[status] ?? []).length}
                  color={selectedTab === status ? 'primary' : 'default'}
                />
              </span>
            }
            value={status}
          />
        ))}
      </Tabs>

      <div className={styles.table}>
        <InvitesTable invites={(inviteMap ?? {})[selectedTab] ?? []} />
      </div>
    </div>
  )
}
