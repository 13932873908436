import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { ReactNode } from 'react'

import { ErrorComponent } from '../components/error/ErrorComponent'
import { Loader } from '../components/loader/Loader'
import { Config } from './Config'
import { ConfigContext } from './ConfigContext'

export interface ConfigProviderProps {
  children?: ReactNode
}

export const ConfigProvider = ({ children }: ConfigProviderProps) => {
  const configFile = process.env.NODE_ENV !== 'production' ? `config.${process.env.NODE_ENV}.json` : 'config.json'

  const { isLoading, data, error } = useQuery(
    ['config'],
    () => {
      return axios.get('/' + configFile).then(({ data }) => new Config(data))
    },
    {
      staleTime: Infinity,
    },
  )

  if (isLoading) {
    return <Loader />
  } else if (error) {
    return <ErrorComponent title={'Aw, Snap!'} summary={'Unable to load config file'} />
  } else {
    return <ConfigContext.Provider value={data as Config}>{children}</ConfigContext.Provider>
  }
}
