import classNames from 'classnames'
import { useState } from 'react'
import { Collapse } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as ChevronIcon } from '../../../assets/icons/chevron.svg'
import { ReactComponent as UserIcon } from '../../../assets/icons/user.svg'
import { useAuthClient } from '../../../auth/useAuthClient'
import { Button } from '../../bootstrap/Button'

import styles from './user-profile.module.scss'

export interface UserProfileProps {
  image?: string
  name: string
}

export const UserProfile = ({ image, name }: UserProfileProps) => {
  const [expanded, setExpanded] = useState(false)

  const authClient = useAuthClient()
  const navigate = useNavigate()

  return (
    <div className={styles.container}>
      <Button
        variant={'transparent'}
        className={classNames(styles.profileButton, expanded && styles.expanded)}
        onClick={() => setExpanded((e) => !e)}
      >
        <UserIcon className={styles.image} />
        <span className={styles.name}>{name}</span>
        <ChevronIcon className={classNames(styles.chevron, expanded && styles.expanded)} />
      </Button>
      <Collapse in={expanded}>
        <div className={styles.menu}>
          <Button
            variant={'transparent'}
            className={classNames(styles.item, styles.logout)}
            onClick={async () => {
              await authClient.logout()
              navigate('/login')
            }}
          >
            Logout
          </Button>
        </div>
      </Collapse>
    </div>
  )
}
