import { Outlet } from 'react-router-dom'

import { DbProvider } from '../../providers/DbProvider'

export function DatabaseLayoutPage() {
  return (
    <DbProvider>
      <Outlet />
    </DbProvider>
  )
}
