import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { ProviderProps, createContext, useContext } from 'react'

import { useApiClient } from '../app/useApiClient'
import { Loader } from '../components/loader/Loader'
import { Invite, InviteStatusMap, InviteStatuses } from '../types/invite.type'

interface IInvitesContext {
  invites: Invite[]
  refetchHook: UseQueryResult['refetch']
}

const InvitesContext = createContext<IInvitesContext>({} as IInvitesContext)

export function InvitesProvider({ children }: Pick<ProviderProps<any>, 'children'>) {
  const apiClient = useApiClient()

  const invitesQuery = useQuery<Invite[]>(['invites'], async () => {
    const { data } = await apiClient.get<Invite[]>('invites')
    data.forEach((x) => (x.status = x.verificationRequest || x.acceptedAt ? x.status : 'expired'))

    console.debug({ data })

    return data
  })

  if (invitesQuery.isLoading) {
    return <Loader />
  } else if (invitesQuery.data) {
    return (
      <InvitesContext.Provider
        value={{
          invites: invitesQuery.data,
          refetchHook: invitesQuery.refetch,
        }}
      >
        {children}
      </InvitesContext.Provider>
    )
  } else {
    throw invitesQuery.error
  }
}

function createInviteStatusMap(invites: Invite[]): InviteStatusMap {
  return invites.reduce(
    (map, invite) => ({ ...map, [invite.status]: [...(map[invite.status] ?? []), invite] }),
    Object.fromEntries(InviteStatuses.map((status) => [status, [] as Invite[]])) as InviteStatusMap,
  )
}

export function useCompanyInvites(): InviteStatusMap {
  const invitesContext = useContext(InvitesContext)
  const companyInviteMap = createInviteStatusMap(invitesContext.invites.filter((x) => x.type === 'company'))
  return companyInviteMap
}

export function useUserInvites(): InviteStatusMap {
  const invitesContext = useContext(InvitesContext)
  const companyInviteMap = createInviteStatusMap(invitesContext.invites.filter((x) => x.type === 'company'))
  return companyInviteMap
}

export function useInvitesRefetch() {
  const invitesContext = useContext(InvitesContext)
  return invitesContext.refetchHook
}
