export const InviteStatuses = ['new', 'accepted', 'expired'] as const
export type InviteStatus = typeof InviteStatuses[number]

export interface Invite {
  channel: InviteChannel
  createdAt: Date
  email: string
  verificationRequest?: {
    expiryAt: Date
  }
  expiryAt: Date
  acceptedAt?: Date
  sentBy?: {
    name: string
    email: string
  }
  name: string
  phoneNumber: string
  sentAt: Date
  status: InviteStatus
  type: InviteType
  updatedAt: Date
  _id: string
  company?: {
    createdAt: Date
  }
}

export const InviteTypes = ['company', 'user'] as const
export type InviteType = typeof InviteTypes[number]

export const InviteChannels = ['email', 'phone'] as const
export type InviteChannel = typeof InviteChannels[number]

export interface CreateInvite {
  name: string
  email: string
  phoneNumber: string
  type: InviteType
  channel: InviteChannel
}

export const InviteStatusTitleMap: Record<InviteStatus, string> = {
  new: 'Pending',
  accepted: 'Accepted',
  expired: 'Expired',
}

export type InviteStatusMap = Record<InviteStatus, Invite[]>
