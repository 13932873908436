import { ExpandLessRounded, ExpandMoreRounded } from '@mui/icons-material'
import { IconButton, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { createColumnHelper, flexRender, getCoreRowModel, getExpandedRowModel, useReactTable } from '@tanstack/react-table'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'

import { useApiClient } from '../../app/useApiClient'
import { DataTable } from '../../components/data-table/DataTable'
import { SingleLineForm } from '../../components/single-line-form/SingleLineForm'
import { useDbData } from '../../providers/DbProvider'
import type { Company, CompanyAcl } from '../../types/company.type'

import styles from './company-list-page.module.scss'

interface CreateCompanyDto {
  ownerId: string
  name: string
  gstin?: string
}

export function CompanyListPage() {
  const { companies, refreshCompanies, users } = useDbData()
  const apiClient = useApiClient()

  const columnHelper = createColumnHelper<Company>()

  const table = useReactTable({
    data: companies,
    columns: [
      columnHelper.display({
        id: 'expand',
        cell: (props) => (
          <>
            {props.row.getCanExpand() && (
              <IconButton onClick={() => props.row.toggleExpanded()}>
                {props.row.getIsExpanded() ? <ExpandLessRounded /> : <ExpandMoreRounded />}
              </IconButton>
            )}
          </>
        ),
        meta: {
          className: styles.colExpand,
        },
      }),
      columnHelper.display({ header: 'Company Details', cell: (props) => <CompanyDetailsCell company={props.row.original} /> }),
      columnHelper.display({ header: 'Additional Info', cell: (props) => <AdditionalInfoCell company={props.row.original} /> }),
      columnHelper.display({ header: 'Number of Users', cell: (props) => props.row.original.acls.length }),
    ],
    getCoreRowModel: getCoreRowModel(),
    getRowCanExpand: ({ original }) => original.acls.length > 0,
    getExpandedRowModel: getExpandedRowModel(),
  })

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden' }}>
      <SingleLineForm<CreateCompanyDto>
        fields={[
          {
            name: 'ownerId',
            label: 'Owner',
            type: 'select',
            formRegisterOptions: { required: true },
            selectMenuItems: users.map((user) => ({
              label: user.email,
              value: user._id,
            })),
          },
          { name: 'name', label: 'Name', type: 'text', formRegisterOptions: { required: true } },
          {
            name: 'gstin',
            label: 'GSTIN',
            type: 'text',
          },
        ]}
        defaultValues={{ ownerId: '' }}
        onSubmit={async (data) => {
          try {
            data.gstin = data.gstin ? data.gstin : undefined
            await apiClient.post('companies', data)
            toast.success('Company created successfully')
            await refreshCompanies()
          } catch (e) {
            console.error(e)
            toast.error('Error in creating company')
          }
        }}
        submitButtonText={'Add Company'}
      />
      <DataTable table={table} hoverRows={true} renderSubComponent={({ row }) => <CompanyUsersList company={row.original} />} />
    </div>
  )
}

interface CellProps {
  company: Company
}

function CompanyDetailsCell({ company }: CellProps) {
  return (
    <>
      <div className={styles.p1}>{company.name}</div>
      <div className={styles.p2}>{company.gstin}</div>
    </>
  )
}

function AdditionalInfoCell({ company }: CellProps) {
  return (
    <Table size={'small'} className={styles.additionalInfo}>
      <TableBody>
        <TableRow>
          <TableCell className={styles.p2}>Short Code</TableCell>
          <TableCell className={styles.p2}>:</TableCell>
          <TableCell className={styles.p2}>{company.shortCode}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={styles.p2}>Tally Last Sync</TableCell>
          <TableCell className={styles.p2}>:</TableCell>
          <TableCell className={styles.p2}>
            {company.integrations.tally.syncDetails?.lastSyncAt
              ? dayjs(company.integrations.tally.syncDetails.lastSyncAt).format('DD MMM YYYY, hh:mm A')
              : 'Pending'}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

interface AddUserDto {
  name: string
  email: string
}

function CompanyUsersList({ company }: { company: Company }) {
  const apiClient = useApiClient()

  const companyUsersQuery = useQuery(['companies', company._id, 'users'], async (context) => {
    const response = await apiClient.get<CompanyAcl[]>(context.queryKey.join('/'))
    return response.data
  })

  const columnHelper = createColumnHelper<CompanyAcl>()

  const table = useReactTable({
    data: companyUsersQuery.data ?? [],
    columns: [columnHelper.accessor('user.name', {}), columnHelper.accessor('user.email', {}), columnHelper.accessor('role', {})],
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <div>
      {/*<SingleLineForm<AddUserDto>*/}
      {/*  fields={[*/}
      {/*    { type: 'text', name: 'name', label: 'Name', formRegisterOptions: { required: true } },*/}
      {/*    { type: 'text', name: 'email', label: 'Email', formRegisterOptions: { required: true } },*/}
      {/*  ]}*/}
      {/*  onSubmit={async (data) => {*/}
      {/*    try {*/}
      {/*      await apiClient.post(`companies/${company._id}/users/invites`, data)*/}
      {/*      toast.success('User invite sent successfully')*/}
      {/*    } catch (e) {*/}
      {/*      console.error(e)*/}
      {/*      toast.error('Error occurred in sending user invite')*/}
      {/*    }*/}
      {/*  }}*/}
      {/*/>*/}
      <TableContainer>
        <Table>
          <TableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableCell key={header.id}>
                    {!header.isPlaceholder ? flexRender(header.column.columnDef.header, header.getContext()) : null}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {table.getRowModel().rows.map((row) => (
              <TableRow key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>{!cell.getIsPlaceholder() ? flexRender(cell.column.columnDef.cell, cell.getContext()) : null}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            {table.getFooterGroups().map((footerGroup) => (
              <TableRow key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <TableCell key={header.id}>
                    {!header.isPlaceholder ? flexRender(header.column.columnDef.footer, header.getContext()) : null}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  )
}
