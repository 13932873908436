import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useContext, useState } from 'react'

import { Loader } from '../components/loader/Loader'
import { ConfigContext } from '../config/ConfigContext'
import { AuthClient } from './AuthClient'
import { AuthContext } from './AuthContext'

export const AuthProvider = ({ children }: any) => {
  const config = useContext(ConfigContext)

  const [client] = useState(new AuthClient({ appName: config.appName, baseUrl: config.apiUrl }))

  const { isLoading, isSuccess, error } = useQuery<string, AxiosError>(['token'], async () => {
    await client.refreshToken()
    return client.accessToken as string
  })

  if (isSuccess || error?.response?.status === 401) {
    return <AuthContext.Provider value={client}>{children}</AuthContext.Provider>
  } else if (isLoading) {
    return <Loader />
  } else {
    throw error
  }

  // const [client] = useState(new AuthClient({baseUrl: config.apiURL, googleClientId: config.googleClientId}))
  // const [authState, setAuthState] = useState(defaultAuthState)
  //
  // const {isLoading, isSuccess, error} = useQuery<void, AxiosError>('token', () => client.refreshToken(), {
  //   staleTime: 1000 * 60 * 60,
  //   refetchOnReconnect: false,
  //   refetchOnMount: false,
  //   refetchOnWindowFocus: false,
  // })
  //
  // if (error && !(error.name === 'AxiosError' && error.response?.status === 401)) {
  //   throw error
  // }
  //
  // if (isSuccess && !authState.isAuthenticated) {
  //   setAuthState({...authState, isAuthenticated: true, accessToken: client.getAccessToken() as string, idToken: client.getIdToken() as string})
  // }
  //
  // const handleGoogleSignInRedirect = useCallback(
  //   async (opts: GoogleSignInRedirectOpts) => {
  //     await client.handleGoogleSignInRedirect(opts)
  //     setAuthState((state) => ({
  //       ...state,
  //       isAuthenticated: true,
  //       accessToken: client.getAccessToken() as string,
  //       idToken: client.getIdToken() as string,
  //     }))
  //   },
  //   [client, setAuthState],
  // )
  //
  // const logout = useCallback(async () => {
  //   await client.logout()
  //   setAuthState((state) => ({
  //     ...state,
  //     isAuthenticated: false,
  //     accessToken: '',
  //     idToken: '',
  //   }))
  // }, [client, setAuthState])
  //
  // // const msalInstance = new PublicClientApplication({
  // //   auth: {
  // //     clientId: "2ff24f41-e9ec-4bf7-bb46-a3e9eb73701d",
  // //     authority: "https://login.microsoftonline.com/counts.ac",
  // //     // redirectUri: "http://localhost:3000/auth/microsoft/redirect",
  // //   },
  // //   system: {
  // //     proxyUrl: "http://localhost:3000",
  // //     loggerOptions: {
  // //       logLevel: LogLevel.Trace,
  // //       loggerCallback: (level, message, containsPii) => {
  // //         // if (containsPii) {
  // //         //   return;
  // //         // }
  // //         switch (level) {
  // //           case LogLevel.Error:
  // //             console.error(message);
  // //             return;
  // //           case LogLevel.Info:
  // //             console.info(message);
  // //             return;
  // //           case LogLevel.Verbose:
  // //             console.debug(message);
  // //             return;
  // //           case LogLevel.Warning:
  // //             console.warn(message);
  // //             return;
  // //         }
  // //       },
  // //       piiLoggingEnabled: true
  // //     }
  // //   }
  // // })
  //
  // return isLoading ? (
  //   <Loader/>
  // ) : (
  //   <AuthContext.Provider
  //     value={{
  //       ...authState,
  //       initiateGoogleSignIn: client.initiateGoogleSignIn.bind(client),
  //       handleGoogleSignInRedirect: handleGoogleSignInRedirect,
  //       refreshToken: client.refreshToken.bind(client),
  //       logout: logout,
  //     }}
  //   >
  //     {/*<MsalProvider instance={msalInstance}>*/}
  //     {children}
  //     {/*</MsalProvider>*/}
  //   </AuthContext.Provider>
  // )
}
