import axios, { AxiosError, AxiosInstance } from 'axios'
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAuthClient } from '../auth/useAuthClient'
import { useConfig } from '../config/useConfig'

export const useApiClient = (): AxiosInstance => {
  const config = useConfig()
  const authClient = useAuthClient()
  const navigate = useNavigate()

  const refreshTokenPromiseRef = useRef<any>()

  const axiosInstance = axios.create({ baseURL: config.apiUrl })

  axiosInstance.interceptors.request.use((config) => {
    // console.debug('request success interceptor', config)
    config.headers = { ...config.headers, authorization: `Bearer ${authClient.accessToken}` }
    return config
  })

  axiosInstance.interceptors.response.use(
    (response) => {
      // console.debug('response success interceptor', response)
      return response
    },
    (error: AxiosError) => {
      // console.debug('response error interceptor', error)
      if (error.response?.status === 401) {
        if (refreshTokenPromiseRef.current === undefined) {
          // console.debug('refreshTokenPromiseRef.current === undefined')
          refreshTokenPromiseRef.current = authClient
            .refreshToken()
            .then(() => {
              // console.debug('after refreshToken success')
              refreshTokenPromiseRef.current = undefined
            })
            .catch((error) => {
              // console.debug('after refreshToken error')
              refreshTokenPromiseRef.current = undefined
              navigate('/login')
              return Promise.reject(error)
            })
        }

        // console.debug('adding callback to refreshTokenPromiseRef')
        refreshTokenPromiseRef.current = refreshTokenPromiseRef.current.then(() => {
          // console.debug('recalling API after refresh', error.config, authClient.accessToken)
          return axiosInstance.request({
            ...error.config,
            headers: { ...error.config.headers, authorization: `Bearer ${authClient.accessToken}` },
          })
        })

        return refreshTokenPromiseRef.current
      }
      return Promise.reject(error)
    },
  )

  return axiosInstance
}
